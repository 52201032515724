<template>
  <v-container id="data-tables" tag="section">
    <base-material-card
      class="px-5 py-3"
      color="primary"
      icon="mdi-transit-transfer"
      inline
      :title="$t('transfers')"
    >
      <v-spacer></v-spacer>

      <template v-slot:after-heading></template>

      <!-- Boton nuevo registro -->
      <template v-slot:corner-button>
        <v-btn small class="ma-2" elevation="2" color="primary" @click.stop="dialog = true"
          >Nuevo Traslado</v-btn
        >
      </template>

      <!-- Overlay para retroalimentar al usuario -->
      <v-overlay :value="overlay">
        <v-progress-circular color="success" indeterminate :size="50"></v-progress-circular>
      </v-overlay>

      <!-- Barra de busqueda -->
      <v-text-field
        append-icon="mdi-magnify"
        class="ml-auto"
        hide-details
        label="Búsqueda"
        single-line
        style="max-width: 250px"
        v-model="search"
      />

      <!-- Dialogo Cancelar -->
      <ConfirmationDialog v-model="isSure" @click="cancel" />

      <!-- Dialogo Nuevo Agregar/Editar Traslado -->
      <v-dialog max-width="500px" no-click-animation persistent v-model="dialog">
        <v-card>
          <!-- <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title> -->
          <v-card-text>
            <base-subheading :subheading="formTitle" />
            <v-container>
              <v-row>
                <!-- Clave del Traslado -->
                <v-col cols="6">
                  <v-text-field
                    dense
                    label="Clave*"
                    maxlength="50"
                    required
                    v-model="$v.editedItem.clave.$model"
                    :error-messages="claveErrors"
                    @blur="$v.editedItem.clave.$touch()"
                    @change="onTextChange"
                    @input="$v.editedItem.clave.$reset()"
                  ></v-text-field>
                </v-col>
                <!-- <v-col cols="12">
                  <v-text-field
                    dense
                    label="Traslado*"
                    maxlength="50"
                    required
                    v-model="$v.editedItem.traslado.$model"
                    :error-messages="trasladoErrors"
                    @blur="$v.editedItem.traslado.$touch()"
                    @input="$v.editedItem.traslado.$reset()"
                  ></v-text-field>
                </v-col> -->
                <!-- Traduccion traslado -->
                <v-col cols="12">
                  <v-select
                    dense
                    item-text="etiqueta"
                    item-value="etiqueta"
                    label="Traslado*"
                    persistent-hint
                    append-outer-icon="mdi-translate"
                    v-model="$v.editedItem.traslado.$model"
                    :error-messages="trasladoErrors"
                    :hint="hintTextTraslado"
                    :items="locales"
                    @input="changed = true"
                    @change="ObtenerHintText($v.editedItem.traslado.$model, 'traslado')"
                  >
                  </v-select>
                </v-col>
                <!-- <v-col cols="12">
                  <v-text-field
                    dense
                    v-model="$v.editedItem.tipo.$model"
                    label="Tipo*"
                    maxlength="50"
                    required
                    :error-messages="tipoErrors"
                    @blur="$v.editedItem.tipo.$touch()"
                    @input="$v.editedItem.tipo.$reset()"
                  ></v-text-field>
                </v-col> -->
                <!-- Traduccion tipo de traslado -->
                <v-col cols="12">
                  <v-select
                    dense
                    item-text="etiqueta"
                    item-value="etiqueta"
                    label="Tipo Traslado*"
                    persistent-hint
                    append-outer-icon="mdi-translate"
                    v-model="$v.editedItem.tipo.$model"
                    :error-messages="tipoErrors"
                    :hint="hintTextTipo"
                    :items="locales"
                    @input="changed = true"
                    @change="ObtenerHintText($v.editedItem.tipo.$model, 'tipo')"
                  >
                  </v-select>
                </v-col>
                <!-- Descripción del Traslado -->
                <!-- <v-col cols="12">
                  <v-text-field
                    dense
                    label="Descripción"
                    maxlength="100"
                    v-model="$v.editedItem.descripcion.$model"
                    :error-messages="descripcionErrors"
                    @input="$v.editedItem.descripcion.$reset()"
                    @blur="$v.editedItem.descripcion.$touch()"
                  ></v-text-field>
                </v-col> -->
                <!-- Traduccion descripción de traslado -->
                <v-col cols="12">
                  <v-select
                    dense
                    item-text="etiqueta"
                    item-value="etiqueta"
                    label="Descripción*"
                    persistent-hint
                    append-outer-icon="mdi-translate"
                    v-model="$v.editedItem.descripcion.$model"
                    :error-messages="descripcionErrors"
                    :hint="hintTextDescripcion"
                    :items="locales"
                    @input="changed = true"
                    @change="ObtenerHintText($v.editedItem.descripcion.$model, 'descripcion')"
                  >
                  </v-select>
                </v-col>
              </v-row>
            </v-container>
            <small>*campo requerido</small>
          </v-card-text>

          <!-- Botones y Crear Otro -->
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-checkbox
              label="Crear otro"
              v-if="editedIndex === -1"
              v-model="checkbox"
              :disabled="isEditing"
            ></v-checkbox>
            <v-btn color="error" text @click="checkChanges">Cancelar</v-btn>
            <v-btn color="primary" text @click="save" :disabled="$v.$invalid">Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-divider class="mt-3" />

      <!-- Data Table de tipo de Traslado  -->
      <v-data-table
        dense
        loading-text="Cargando... Espere por favor"
        :headers="headers"
        :items="traslados"
        :loading="isLoading"
        :search.sync="search"
      >
        <!-- Slot del boton de Activo-->
        <template v-slot:item.activo="{ item }">
          <Status :activo="item.activo" @statusChanged="changeStatus(item)" />
        </template>
        <!-- Slot actions editar y eliminar -->
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
        </template>
        <template v-slot:no-data>
          <!-- <v-btn color="primary">Reset</v-btn> -->
          ¡No hay datos para mostrar!
        </template>
      </v-data-table>

      <!-- DataExporter -->
      <DataExporter :dataArray="traslados" :dataFields="fields" fileName="Traslados" />
    </base-material-card>
  </v-container>
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'

import ConfirmationDialog from '@/components/general/ConfirmationDialog.vue'
import DataExporter from '@/components/general/DataExporter.vue'
import Status from '@/components/general/StatusInfo.vue'

export default {
  components: {
    ConfirmationDialog,
    DataExporter,
    Status,
  },

  data: () => ({
    changed: false,
    checkbox: false,
    dialog: false,
    editedIndex: -1,
    hintTextDescripcion: 'Seleccione la ruta de localizacion',
    hintTextTipo: 'Seleccione la ruta de localizacion',
    hintTextTraslado: 'Seleccione la ruta de localizacion',
    isEditing: false,
    isLoading: false,
    isSure: false,
    overlay: false,
    search: undefined,
    headers: [
      { text: 'Clave', align: 'start', sortable: true, value: 'clave' },
      { text: 'Traslado', sortable: true, value: 'traslado' },
      { text: 'Tipo', sortable: true, value: 'tipo' },
      { text: 'Descripción', value: 'descripcion' },
      { text: 'Activo', value: 'activo' },
      { text: 'Acciones', value: 'actions', sortable: false },
    ],
    locales: [],
    traslados: [],

    defaultItem: {
      traslado: '',
      descripcion: '',
      tipo: '',
      clave: '',
    },
    editedItem: {
      traslado: '',
      descripcion: '',
      tipo: '',
      clave: '',
    },
    fields: {
      Clave: 'clave',
      Traslado: 'traslado',
      Tipo: 'tipo',
      Descripción: 'descripcion',
    },
  }),

  mixins: [validationMixin],
  //Validaciones para los campos del formulario
  validations: {
    editedItem: {
      clave: { required, minLength: minLength(3), maxLength: maxLength(50) },
      traslado: { required, minLength: minLength(3), maxLength: maxLength(50) },
      tipo: { required, minLength: minLength(3), maxLength: maxLength(50) },
      descripcion: { minLength: minLength(3), maxLength: maxLength(100) },
    },
  },

  computed: {
    // Propiedad computada para errores en campo clave
    claveErrors() {
      const errors = []
      if (!this.$v.editedItem.clave.$dirty) return errors
      !this.$v.editedItem.clave.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.clave.minLength &&
        errors.push('Este campo debe de tener un mínimo de 3 caracteres.')
      !this.$v.editedItem.clave.maxLength &&
        errors.push('Este campo debe de tener un máximo de 20 caracteres.')
      return errors
    },

    // Propiedad computada para errores en campo descripción
    descripcionErrors() {
      const errors = []
      if (!this.$v.editedItem.descripcion.$dirty) return errors
      !this.$v.editedItem.descripcion.minLength &&
        errors.push('Este campo debe de tener un mínimo de 3 caracteres.')
      !this.$v.editedItem.descripcion.maxLength &&
        errors.push('Este campo debe de tener un máximo de 70 caracteres.')
      return errors
    },

    // Propiedad computada para devolver titulo del dialogo
    formTitle() {
      return this.editedIndex === -1 ? 'Nuevo Tipo de Traslado' : 'Editar Tipo de Traslado'
    },

    // Propiedad computada para errores en campo tipo
    tipoErrors() {
      const errors = []
      if (!this.$v.editedItem.tipo.$dirty) return errors
      !this.$v.editedItem.tipo.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.tipo.minLength &&
        errors.push('Este campo debe de tener un mínimo de 3 caracteres.')
      !this.$v.editedItem.tipo.maxLength &&
        errors.push('Este campo debe de tener un máximo de 20 caracteres.')
      return errors
    },

    // Propiedad computada para errores en campo traslado
    trasladoErrors() {
      const errors = []
      if (!this.$v.editedItem.traslado.$dirty) return errors
      !this.$v.editedItem.traslado.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.traslado.minLength &&
        errors.push('Este campo debe de tener un mínimo de 3 caracteres.')
      !this.$v.editedItem.traslado.maxLength &&
        errors.push('Este campo debe de tener un máximo de 20 caracteres.')
      return errors
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
  },

  async created() {
    this.obtenerLocales()
    this.obtenerTraslados()
  },

  methods: {
    /**
     * @description confirma la cancelacion de la edicion en el formulario de mantenimiento
     * de usuarios
     */
    cancel(isSure) {
      this.isSure = isSure
      if (this.isSure) {
        this.close(false)
        this.changed = false
      }
    },

    /**
     * @description Cambia el estatus de un traslado
     */
    changeStatus(item) {
      this.isEditing = true
      this.editedIndex = this.traslados.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.editedItem.activo = !this.editedItem.activo
      let body = {
        traslado: this.editedItem.traslado,
        descripcion: this.editedItem.descripcion,
        tipo: this.editedItem.tipo,
        clave: this.editedItem.clave,
        activo: this.editedItem.activo,
      }
      let payload = { id: this.editedItem._id, body: body }
      this.$store
        .dispatch('transfer/editTransfer', payload)
        .then(() => {
          this.$store.commit('ALERT', {
            color: 'success',
            text: this.editedItem.activo ? 'Se activo el traslado' : 'Se desactivo el traslado',
          })
          this.overlay = false
        })
        .catch((err) => {
          this.$store.commit('ALERT', {
            color: 'error',
            text: err.response.data.msg,
            // Enviar a inicio de sesión
          })
          this.overlay = false
        })
      Object.assign(this.traslados[this.editedIndex], this.editedItem)
    },

    /**
     * @description Revisa si hay cambios en el formulario de mantenimiento de
     * usuarios
     */
    checkChanges() {
      if (this.changed) {
        this.isSure = true
      } else {
        this.close(false)
        this.changed = false
      }
    },

    /**
     * @description Limpia todos los elementos usados en el dialogo de
     * mantenimiento de traslados
     */
    clearDialog() {
      this.hintTextDescripcion = 'Seleccione la ruta de localizacion'
      this.hintTextTipo = 'Seleccione la ruta de localizacion'
      this.hintTextTraslado = 'Seleccione la ruta de localizacion'
    },

    /**
     * @description Cierra el diálogo de mantenimiento de traslados
     */
    close(checkbox) {
      this.isSure = false
      this.checkbox = checkbox
      if (!this.checkbox) {
        this.dialog = false
        this.isEditing = false
      }

      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.clearDialog()
      })
      this.$v.$reset()
    },

    /**
     * @description elimina un registro de la tabla y de la base de datos
     */
    deleteItem(item) {
      const index = this.traslados.indexOf(item)
      confirm('Esta seguro que desea eliminar este registro?') && this.grupos.splice(index, 1)
      // TODO implementar borrado del registro
    },

    /**
     * @description prepara un registro para su edición y muestra el dialogo
     * para editar traslados
     */
    editItem(item) {
      this.isEditing = true
      this.editedIndex = this.traslados.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.ObtenerHintText(this.editedItem.traslado, 'traslado')
      this.ObtenerHintText(this.editedItem.tipo, 'tipo')
      this.ObtenerHintText(this.editedItem.descripcion, 'descripcion')
      this.dialog = true
    },

    /**
     * @description Obtiene el hint para un select mostrando las Traducciones
     * al idioma ingles y Español
     *
     * @param (string) valor Valor de la etiqueta a buscar
     * @param (string) tipo Valor del select para el que se genera el hint
     */
    ObtenerHintText(valor, tipo) {
      const resultado = this.locales.find((etiqueta) => etiqueta.etiqueta === valor)
      const en = resultado.locales.find((idioma) => idioma.clave === 'en')
      const es = resultado.locales.find((idioma) => idioma.clave === 'es')
      const resp = `(EN) ${en.valor} - (ES) ${es.valor}`

      switch (tipo) {
        case 'traslado':
          this.hintTextTraslado = resp
          break
        case 'tipo':
          this.hintTextTipo = resp
          break
        case 'descripcion':
          this.hintTextDescripcion = resp
          break
        default:
          break
      }
    },

    /**
     * @description Obtiene un listado de todos los locales
     */
    async obtenerLocales() {
      this.isLoading = true

      let payload = {}
      this.$store
        .dispatch('localization/fetchLocales', payload)
        .then((response) => {
          this.locales = response.data
        })
        .catch((err) => {
          this.isLoading = false
          this.$store.commit('ALERT', {
            color: 'error',
            text: err.response.data.error,
          })
        })
    },

    /**
     * @description Obtiene un listado de todos traslados
     */
    async obtenerTraslados() {
      // this.isLoading = true

      let payload = {}
      this.$store
        .dispatch('transfer/fetchTransfer', payload)
        .then((response) => {
          this.traslados = response.data
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.$store.commit('ALERT', {
            color: 'error',
            text: err.response.data.error,
            // Enviar a inicio de sesión
          })
        })
    },

    /**
     * @description Marca que ha cambiado el valor de un campo
     */
    onTextChange() {
      this.changed = true
    },

    /**
     * @description Guarda el contenido del formulario ya sea para un traslado nuevo o
     * la edicion de un traslado
     */
    async save() {
      this.overlay = true
      this.$v.$touch()
      if (!this.$v.$invalid) {
        let body = {
          traslado: this.editedItem.traslado,
          descripcion: this.editedItem.descripcion,
          tipo: this.editedItem.tipo,
          clave: this.editedItem.clave,
        }

        let payload = { body: body }
        if (this.editedIndex > -1) {
          // Si es edición
          payload.id = this.editedItem._id
          this.$store
            .dispatch('transfer/editTransfer', payload)
            .then(() => {
              this.$store.commit('ALERT', {
                color: 'success',
                text: 'El registro se actualizo con éxito',
              })
              this.overlay = false
            })
            .catch((err) => {
              this.$store.commit('ALERT', {
                color: 'error',
                text: err.response.data.msg,
                // Enviar a inicio de sesión
              })
              this.overlay = false
            })
          Object.assign(this.traslados[this.editedIndex], this.editedItem)
        } else {
          // Si es un registro nuevo
          this.$store
            .dispatch('transfer/addTransfer', payload)
            .then((response) => {
              this.$store.commit('ALERT', {
                color: 'success',
                text: 'El registro se creo con éxito',
              })
              this.traslados.push(response.data)
              this.overlay = false
            })
            .catch((err) => {
              this.$store.commit('ALERT', {
                color: 'error',
                text: err.response.data.msg,
                // Enviar a inicio de sesión
              })
              this.overlay = false
            })
        }
        this.close(this.checkbox)
      }
    },
  },
}
</script>
